<template>
  <a-select
    :value="value"
    show-search
    class="w-100"
    size="large"
    :options="options"
    @change="handleChange"
    style="text-align: center"
  >
    <template #option="{ label }" style="text-align: center;"> {{ label }} </template>
  </a-select>
</template>

<script>
// import { defineComponent, onMounted, ref, watch } from "vue";
export default {
  name: "years-selector",
  emits: ["selected"],
  props: ["value"],
  setup(props, { emit }) {
    const handleChange = (value) => {
      console.log('value',value);
      emit("selected", value);
    };
    const yearsList = () => {
      const currentYear = new Date().getFullYear();
      const yearAmount = currentYear - 1950;
      return Array.from(Array(yearAmount).keys())
        .map((ele) => currentYear - ele)
        .sort((a, b) => b - a)
        .map((ele) => {
          return {
            label: ele,
            value: ele,
          };
        });
    };
    return {
      options: yearsList(),
      handleChange,
    };
  },
};
</script>

<style>
</style>